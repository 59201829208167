<template>
    <section class="sidebar w-64 xl2:w-80 top-0 h-full fixed flex flex-col z-10">
        <header class="sidebar-header">
            <a v-if="showBackButton"
               href="#"
               @click.prevent="back"
               class="sidebar-back-icon absolute inline-block top-5 left-5 xl2:top-6 xl2:left-6 transition-all duration-300 hover:scale-110 relative">
                <div class="absolute w-full h-full p-2">
                    <div class="bg-white rounded-full w-full h-full"></div>
                </div>
                <icon name="back-circle" class="w-full h-full absolute"/>
            </a>

            <div class="sidebar-photo-container absolute">
                <img class="sidebar-photo w-full h-full absolute rounded-full" :src="profilePicture" alt="">
                <div class="sidebar-photo-circle w-full h-full absolute rounded-full"></div>
            </div>
        </header>

        <div class="flex-grow">
            <h1 class="sidebar-user-name break-words ml-14 mr-8 mb-12 leading-6 font-bold">
                <span class="font-normal">{{ firstName }}</span><br>{{ lastName }}
            </h1>

            <nav class="sidebar-nav">
                <ul>
                    <li>
                        <router-link :to="{name: 'account-profile'}">
                            <span class="sidebar-nav-ball"></span>
                            <span class="sidebar-nav-text sidebar-nav-profile">Profile</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'account-password'}">
                            <span class="sidebar-nav-ball"></span>
                            <span class="sidebar-nav-text sidebar-nav-password">Password</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'account-apps'}">
                            <span class="sidebar-nav-ball"></span>
                            <span class="sidebar-nav-text sidebar-nav-apps">My Apps</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'account-legal'}">
                            <span class="sidebar-nav-ball"></span>
                            <span class="sidebar-nav-text sidebar-nav-legal">Legal</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="#" @click.prevent="logOut">
                            <span class="sidebar-nav-ball"></span>
                            <span class="sidebar-nav-text sidebar-nav-logout">Log Out</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

        <footer class="py-5 xl2:py-8 px-8 xl2:px-10">
            <div class="sidebar-footer-text-color">
                <img src="@/assets/img/account/dayllo-logo-footer.svg"
                     class="w-12 xl2:w-16 mb-2 xl2:mb-3 opacity-70"
                     alt="">
                <p class="leading-tight xl2:leading-snug text-xs">Copyright © {{ year }}.</p>
                <p class="leading-tight xl2:leading-snug text-xs">All rights reserved. | WISIT, s.r.o.</p>
            </div>
        </footer>
    </section>
</template>

<script>
import Icon from '@/components/front/Icon';
import AxiosUtils from '@/utils/AxiosUtils';
import {notificationMixin} from '@/utils/notification';

export default {
    name: 'Sidebar',
    components: {Icon},
    mixins: [notificationMixin],
    props: {
        showBackButton: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            firstName: this.$store.getters['auth/firstName'],
            lastName: this.$store.getters['auth/lastName'],
            profilePicture: this.$store.getters['auth/profilePicture'],
            year: new Date().getFullYear(),
        }
    },

    mounted() {
        this.profilePictureUnwatch = this.$store.watch(
            (state, getters) => getters['auth/profilePicture'],
            newValue => this.profilePicture = newValue,
        );

        this.firstNameUnwatch = this.$store.watch(
            (state, getters) => getters['auth/firstName'],
            newValue => this.firstName = newValue,
        );

        this.lastNameUnwatch = this.$store.watch(
            (state, getters) => getters['auth/lastName'],
            newValue => this.lastName = newValue,
        );
    },

    beforeUnmount() {
        this.profilePictureUnwatch?.();
        this.firstNameUnwatch?.();
        this.lastNameUnwatch?.();
    },

    methods: {
        logOut() {
            this.$http.post(this.$urls.authLogout, {}).then(res => {
                this.clearNotifications();

                if (AxiosUtils.isSuccess(res)) {
                    window.location.href = process.env.BASE_URL;
                } else {
                    this.errorNotification();
                }
            }).catch(err => {
                this.clearNotifications();

                if ('response' in err && err.response) {
                    if (AxiosUtils.redirectIfUnauthorized(err.response)) {
                        return;
                    } else if (AxiosUtils.isForbidden(err.response)) {
                        window.location.href = process.env.BASE_URL;
                        return;
                    }
                }

                this.errorNotification();
            });
        },

        back() {
            this.$router.back();
        },
    },
}
</script>

<style lang="scss" scoped>
@use "../../scss/abstracts" as abs;

.sidebar {
    background: #243454;
    border-radius: 0 5rem 0 0;
}

.sidebar-header {
    min-height: 12rem;
}

.sidebar-back-icon {
    width: 3.25rem;
    height: 3.25rem;
    color: #2a4572;

    &:hover {
        color: #eb1152;
    }
}

.sidebar-photo-container {
    width: 6.75rem;
    height: 6.75rem;
    left: 10.4rem;
    top: 3rem;
    animation: photo 8s ease-in-out infinite;

    @keyframes photo {
        0% { transform: scale(1) translateY(0rem); }
        50% { transform: scale(1.05) translateY(0.5rem); }
        100% { transform: scale(1) translateY(0rem); }
    }
}

.sidebar-photo {
    background-color: #243454;
    padding: 0.3125rem;
}

.sidebar-photo-circle {
    border: 0.375rem solid #2a4572;
}

.sidebar-user-name {
    font-size: 1.4rem;
    color: #c0cae5;
}

.sidebar-nav li {
    padding-left: 2.3rem;

    a {
        display: block;
        color: white;

        .sidebar-nav-ball {
            display: inline-block;
            margin-right: 1rem;
            visibility: hidden;
            box-shadow: 0 0 5px #ffffff4b;
            vertical-align: middle;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: white;
        }

        .sidebar-nav-text {
            display: inline-block;
            font-size: 1rem;
            line-height: 2;
            transition: all 0.2s ease-in-out;
            transform-origin: left;
            vertical-align: middle;
        }

        &:hover,
        &.router-link-active {
            .sidebar-nav-text {
                font-weight: 600;
                transform: scale(1.2);
            }

            .sidebar-nav-profile {
                color: #49cbfc;
            }

            .sidebar-nav-password {
                color: #ffb600;
            }

            .sidebar-nav-apps {
                color: #78e002;
            }

            .sidebar-nav-legal {
                color: #ff5b9d;
            }

            .sidebar-nav-logout {
                color: #ff2323;
            }
        }

        &.router-link-active .sidebar-nav-ball {
            visibility: visible;
        }
    }
}

.sidebar-footer-text-color {
    color: #c0cae596;
}

@media (min-width: abs.$breakpoint-xl2) {
    .sidebar {
        border-radius: 0 7rem 0 0;
    }

    .sidebar-header {
        min-height: 14rem;
    }

    .sidebar-photo-container {
        width: 8rem;
        height: 8rem;
        top: 5rem;
        left: 14rem;
    }

    .sidebar-photo-circle {
        border: 0.44rem solid #2a4572;
    }

    .sidebar-photo {
        padding: 0.25vw;
    }

    .sidebar-back-icon {
        width: 4rem;
        height: 4rem;
    }

    .sidebar-user-name {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-left: 4rem;
        margin-bottom: 3.6rem;
    }

    .sidebar-nav li {
        padding-left: 2.6rem;

        a .sidebar-nav-text {
            font-size: 1.3rem;
        }
    }
}
</style>
