import store from '@/store';
import urls from '@/utils/urls';
import axios from '@/utils/api';
import StringUtil from "@/utils/StringUtil";
import AxiosUtils from '@/utils/AxiosUtils';

/**
 * Bootstrap application
 */
const bootstrap = async (to, _, next) => {
    if (!store.state.bootstrapped) {
        // First page load
        try {
            let res = await axios.get(urls.bootstrap);
            store.state.bootstrapped = true;

            // When user is logged in, a user property is available
            if ('user' in res.data && res.data.user) {
                await store.dispatch('auth/patch', res.data.user);
            }
            next();
        } catch (err) {
            console.error(err);

            let isHome = false;
            for (let route of to.matched) {
                if (route.name === 'home') {
                    isHome = true;
                }
            }

            // When site can't be bootstrapped, return user home
            if (!isHome) {
                next({name: 'home'});
            } else {
                next();
            }
        }
    } else {
        next();
    }
};

/**
 * Redirect user if the user is authenticated and is verified to admin page
 */
const checkAuthentication = (to, _, next) => {
    let meta = to.meta
    let checkAuth = 'checkAuth' in meta && meta.checkAuth === true;

    if (checkAuth) {
        const isVerified = store.getters['auth/isVerified'];
        let isAccountRoute = false;

        for (let route of to.matched) {
            if (route.name === 'account') { // Account route is a parent of all account routes
                isAccountRoute = true;
                break;
            }
        }

        if (!isAccountRoute && isVerified) {
            // A verified user is directly redirected to account page
            next({
                name: 'account-apps',
                replace: true,
            });

            return;
        } else if (isAccountRoute && !isVerified) {
            // An unverified user tries to access account page
            next({
                name: 'auth-login',
                replace: true,
            });

            return;
        }
    }

    next();
};

/**
 * Updates document meta fields from meta route property
 */
const updateDocumentMeta = to => {
    let meta = to.meta;
    if ('title' in meta && meta.title) {
        document.title = meta.title;
    } else {
        document.title = 'Dayllo ideas';
    }

    let robotsMeta = document.head.querySelector('meta[name="robots"]');
    if ('disableIndex' in meta && meta.disableIndex) {
        if (robotsMeta === null) {
            robotsMeta = document.createElement('meta');
            robotsMeta.name = 'robots';
            robotsMeta.content = 'noindex';
            document.head.append(robotsMeta);
        }
    } else if (robotsMeta !== null) {
        robotsMeta.remove();
    }

    let descriptionMeta = document.head.querySelector('meta[name="description"]');
    if ('description' in meta) {
        if (descriptionMeta === null) {
            descriptionMeta = document.createElement('meta');
            descriptionMeta.name = 'description';
            descriptionMeta.content = meta.description;
            document.head.append(descriptionMeta);
        } else {
            descriptionMeta.content = meta.description;
        }
    } else if (descriptionMeta !== null) {
        descriptionMeta.remove();
    }

    let keywordsMeta = document.head.querySelector('meta[name="keywords"]');
    if ('keywords' in meta) {
        if (keywordsMeta === null) {
            keywordsMeta = document.createElement('meta');
            keywordsMeta.name = 'keywords';
            keywordsMeta.content = meta.keywords;
            document.head.append(keywordsMeta);
        } else {
            keywordsMeta.content = meta.keywords;
        }
    } else if (keywordsMeta !== null) {
        keywordsMeta.remove();
    }
};

/**
 * Checks if query contains a parameter 't' and if it fails, redirects user /auth/reset-password
 */
const newPasswordTokenCheck = async (to, _, next) => {
    const queryParam = to.query.t;

    if (typeof queryParam !== 'string' || StringUtil.isBlank(queryParam)) {
        if (store.getters['auth/isVerified']) {
            next({
                name: 'account-apps',
                replace: true,
            });
        } else {
            next({
                name: 'auth-reset-password',
                replace: true,
            });
        }
    } else if (store.getters['auth/isVerified']) {
        await store.dispatch('notification/error', 'This link is not valid');
        next({
            name: 'account-apps',
            replace: true,
        });
    } else {
        next();
    }
};

const checkIdeaExistence = async (to, _, next) => {
    const param = to.params.id;
    if (!param) {
        next({
            name: 'not-found',
            replace: true,
        });
        return;
    }

    try {
        let res = await axios.get(urls.idea({id: param}));
        if ('ideaUri' in res.data && res.data.ideaUri) {
            to.meta.ideaUri = res.data.ideaUri;
        } else {
            to.meta.ideaUri = null;
        }
    } catch (err) {
        if ('response' in err && err.response) {
            if (AxiosUtils.isNotFound(err.response) || AxiosUtils.isBadRequest(err.response)) {
                next({
                    name: 'not-found',
                    replace: true,
                });
                return;
            }
        }

        to.meta.ideaUri = null;
    }

    next();
};

export {
    bootstrap,
    checkAuthentication,
    updateDocumentMeta,
    newPasswordTokenCheck,
    checkIdeaExistence,
};
