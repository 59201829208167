<template>
    <router-link :to="to" class="arrow group">
        <svg class="xl:group-hover:scale-125 transition-all duration-200"
             xmlns="http://www.w3.org/2000/svg" width="44" height="44"
             viewBox="0 0 44 44">
            <circle cx="22" cy="22" r="22" fill="#e9eef5" opacity="0.2" style="isolation:isolate"/>
            <path
                d="M19.54,31.08a1.36,1.36,0,0,1-1.36-1.37h0a1.39,1.39,0,0,1,.43-1l6.69-6.5-6.68-6.5a1.4,1.4,0,0,1-.44-1v0a1.38,1.38,0,0,1,1.38-1.36,1.31,1.31,0,0,1,1,.4l7.55,7.38a1.44,1.44,0,0,1,.5,1.1,1.53,1.53,0,0,1-.5,1.1l-7.55,7.37a1.26,1.26,0,0,1-1,.39Z"
                fill="#e9eef5"/>
        </svg>
    </router-link>
</template>

<script>
export default {
    name: 'BannerArrowRight',
    props: {
        to: {
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@use "../../scss/abstracts" as abs;

.arrow svg {
    width: 3.4rem;
    height: 3.4rem;
}

@media (min-width: abs.$breakpoint-xl) {
    .arrow {
        &:hover {
            circle {
                fill: white;
                opacity: 1;
            }

            path {
                fill: abs.$color-primary;
                stroke-width: 0.5px;
                stroke: abs.$color-primary;
            }
        }

        svg {
            width: 6vh;
            height: 6vh;
        }
    }
}

</style>
