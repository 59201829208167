import {Notification, NotificationType} from '@/utils/notification';

export default {
    namespaced: true,

    state: {
        /**
         * {Notification[]}
         */
        notificationQueue: [],
    },

    mutations: {
        add(state, payload) {
            state.notificationQueue.push(payload);
        },

        poll(state) {
            state.notificationQueue.shift();
        },

        clear(state) {
            state.notificationQueue.splice(0, state.notificationQueue.length);
        },
    },

    getters: {
        head(state) {
            if (state.notificationQueue.length > 0) {
                return state.notificationQueue[0];
            } else {
                return null;
            }
        },
    },

    actions: {
        /**
         * Add a notification to the queue
         *
         * @param commit
         * @param notification
         */
        add({commit}, notification) {
            commit('add', notification);
        },

        /**
         * Convenience method to add default notification without action
         *
         * @param commit
         * @param {String} message
         */
        default({commit}, message) {
            commit('add', new Notification(message, NotificationType.DEFAULT));
        },

        /**
         * Conveniece method to add success notification without action
         *
         * @param commit
         * @param {String} message
         */
        success({commit}, message) {
            commit('add', new Notification(message, NotificationType.SUCCESS));
        },

        /**
         * Convenience method to add error notification without action
         *
         * @param commit
         * @param {String} message
         */
        error({commit}, message = 'We are sorry, but an error occured.') {
            commit('add', new Notification(message, NotificationType.ERROR));
        },

        /**
         * Removes first element from the notifications queue
         *
         * @param commit
         */
        poll({commit}) {
            commit('poll');
        },

        /**
         * Removes all notifications from queue
         *
         * @param commit
         */
        clear({commit}) {
            commit('clear');
        },
    },
};
