<template>
    <header
        :class="['h-16 sm:h-24 lg:h-28 w-full flex items-center fixed transition-all duration-500 z-999', {'active': isScrolled}]">
        <div class="w-1/2 lg:w-3/12">
            <router-link :to="{name: 'home'}">
                <img class="header-logo ml-2 sm:ml-4 lg:ml-8 xl:ml-12 w-32 sm:w-40 xl2:w-52 transition-all duration-500"
                     src="@/assets/img/header/dayllo-ideas-logo.svg"
                     alt="Dayllo ideas logo">
            </router-link>
        </div>

        <div class="w-6/12 hidden lg:flex justify-center items-center">
            <nav>
                <ul class="header-middle-nav flex flex-row">
                    <li class="inline-flex">
                        <router-link :to="{name: 'get-idea'}"
                                     class="group font-medium text-xl text-white transition-all duration-300 xl:hover:scale-125">
                            <span class="get transition-all duration-300 xl:group-hover:text-hover">Get </span>idea.
                        </router-link>
                    </li>
                    <li class="inline-flex">
                        <router-link :to="{name: 'share-idea'}"
                                     class="group font-medium text-xl text-white transition-all duration-300 xl:hover:scale-125">
                            <span class="share transition-all duration-300 xl:group-hover:text-hover">Share </span>idea.
                        </router-link>
                    </li>
                    <li class="inline-flex">
                        <router-link :to="{name: 'rate-idea'}"
                                     class="group font-medium text-xl text-white transition-all duration-300 xl:hover:scale-125">
                            <span class="rate transition-all duration-300 xl:group-hover:text-hover">Rate </span>idea.
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="w-1/2 lg:w-3/12 flex justify-end">
            <router-link :to="{name: 'auth-login'}"
                         class="header-btn hidden sm:block text-primary font-semibold bg-white py-4 px-12 rounded-xl4 transition-all duration-500 xl:hover:bg-hover xl:hover:text-white xl:hover:rounded-xl3 xl:hover:px-14">
                Log In
            </router-link>

            <router-link :to="{name: 'auth-login'}"
                         class="flex sm:hidden cursor-pointer text-white">
                <icon name="login" class="w-9 align-middle"/>
            </router-link>

            <div
                class="header-btn-menu-icon flex justify-center items-center cursor-pointer ml-4 xl2:ml-0 sm:ml-1 w-1/6 mr-5 text-white xl:hover:text-hover transition-all duration-500"
                @click.prevent.stop="menuIsVisible = !menuIsVisible">
                <icon name="menu" class="w-8 sm:w-10 xl2:w-12 align-middle"/>
            </div>
        </div>

        <header-nav
            class="header-nav-container absolute top-19 right-8 sm:top-23 sm:right-20 lg:top-25 xl2:top-30 xl2:right-32"
            :show="menuIsVisible"
            @visible="menuIsVisible = $event"/>
    </header>
</template>

<script>
import HeaderNav from '@/components/front/HeaderNav';
import Icon from '@/components/front/Icon';

export default {
    name: 'MainHeader',
    components: {Icon, HeaderNav},
    data() {
        return {
            menuIsVisible: false,
            isScrolled: false,
            scrollListenerFunc: this.windowScrollListener.bind(this),
        }
    },

    mounted() {
        this.scrollListenerFunc();
        window.addEventListener('scroll', this.scrollListenerFunc);
    },

    unmounted() {
        window.removeEventListener('scroll', this.scrollListenerFunc);
    },

    methods: {
        windowScrollListener() {
            this.isScrolled = window.scrollY > 50;
        },
    }
}
</script>

<style lang="scss" scoped>

@use '../../scss/abstracts' as abs;

.active {
    background: #2c3e63d8;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    box-shadow: (2px 2px 10px #121e29c7);
}

.header-middle-nav li {
    margin: 0 2vw 0 2vw;
}

.header-btn {
    box-shadow: 0 0 20px #00000029;
}

.get {
    color: #a5d621;
}

.share {
    color: #ffb600;
}

.rate {
    color: #ff6464;
}

@media (min-width: abs.$breakpoint-xl) {
    .active:hover {
        background: #2c3e63;
    }

    .header-btn:hover {
        box-shadow: 2px 2px 20px #077ee070;
    }

    .header-logo:hover {
        transform: translateY(0.2em) rotate(5deg);
    }

    .header-btn-menu-icon:hover {
        filter: drop-shadow(2px 2px 10px #077ee0c7);
        transform: scale(1.2);
    }
}

@media (min-width: abs.$breakpoint-xl2) {
    .header-middle-nav li > a {
        font-size: 1.4rem;
    }

    .header-btn {
        padding: 1.25rem 3.75rem;
        border-radius: 3rem;
        font-size: 1.3rem;

        &:hover {
            border-radius: 2rem;
            padding: 1.25rem 4.5rem;
        }
    }
}

@media (max-height: 550px) {
    .header-nav-container {
        right: 1rem;
    }
}
</style>
