<template>
    <router-view/>
</template>

<script>
import {notificationMixin} from '@/utils/notification';

export default {
    mixins: [notificationMixin],
    data() {
        return {
            showApp: false,
        };
    },
}
</script>

<style lang="scss">
@import "src/scss/main";
</style>
