export default {
    namespaced: true,

    state: {
        /**
         * {Modal[]}
         */
        modalQueue: [],

        /**
         * Indicates if modal window is visible or not
         */
        isActive: false,
    },

    mutations: {
        add(state, payload) {
            state.modalQueue.push(payload);
        },

        poll(state) {
            state.modalQueue.shift();
        },

        clear(state) {
            state.modalQueue.splice(0, state.modalQueue.length);
        },

        isActive(state, payload) {
            state.isActive = payload;
        },
    },

    getters: {
        head(state) {
            if (state.modalQueue.length > 0) {
                return state.modalQueue[0];
            } else {
                return null;
            }
        },

        isActive(state) {
            return state.isActive;
        },
    },

    actions: {
        /**
         * Add a modal to the queue
         *
         * @param commit
         * @param modal
         */
        add({commit}, modal) {
            commit('add', modal);
        },

        /**
         * Removes first element from the modal queue
         *
         * @param commit
         */
        poll({commit}) {
            commit('poll');
        },

        /**
         * Removes all modals
         *
         * @param commit
         */
        clear({commit}) {
            commit('clear');
        },

        /**
         * Changes value indicating if the modal window is visible or not
         *
         * @param commit
         * @param {Boolean} isActive
         */
        setIsActive({commit}, isActive) {
            commit('isActive', isActive);
        },
    },
}
