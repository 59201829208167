const AVATAR_PICTURE_LOCATION = process.env.BASE_URL + 'img/';

class Avatar {

    /**
     * @param {String} name
     * @param {String} url
     */
    constructor(name, url) {
        if (this.constructor !== Avatar) {
            throw new Error('Avatar class is final');
        }

        this.name = name;
        this.url = url;
    }
}

const AvatarEnum = Object.freeze({
    BLUE: new Avatar('BLUE', `${AVATAR_PICTURE_LOCATION}avatar_blue.svg`),
    GREEN: new Avatar('GREEN', `${AVATAR_PICTURE_LOCATION}avatar_green.svg`),
    PINK: new Avatar('PINK', `${AVATAR_PICTURE_LOCATION}avatar_pink.svg`),
    PURPLE: new Avatar('PURPLE', `${AVATAR_PICTURE_LOCATION}avatar_purple.svg`),
    TURQUOISE: new Avatar('TURQUOISE', `${AVATAR_PICTURE_LOCATION}avatar_turquoise.svg`),
    WHITE: new Avatar('WHITE', `${AVATAR_PICTURE_LOCATION}avatar_white.svg`),
    YELLOW: new Avatar('YELLOW', `${AVATAR_PICTURE_LOCATION}avatar_yellow.svg`),

    values: function () {
        return [this.BLUE, this.GREEN, this.PINK, this.PURPLE, this.TURQUOISE, this.WHITE, this.YELLOW];
    },

    /**
     * @param {String} name
     * @returns {null|Avatar}
     */
    from: function (name) {
        if (typeof name !== 'string') {
            return null;
        }

        for (let avatar in AvatarEnum) {
            if (avatar === name) {
                return AvatarEnum[avatar];
            }
        }

        return null;
    },

    /**
     * @returns {Avatar}
     */
    random: function () {
        return this.values()[Math.round(Math.random() * (this.values().length - 1))];
    },
});

export default AvatarEnum;
