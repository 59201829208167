<template>
    <header class="fixed top-0 h-20 w-full z-999">
        <div class="mobile-header flex items-center h-full relative z-50">
            <div class="pl-5 w-2/12 flex items-center">
                <a v-show="showBackButton"
                   href="#"
                   class="text-white inline-flex"
                   @click.prevent.stop="back">
                    <icon name="back" class="w-9"/>
                </a>
            </div>

            <div class="flex-grow">
                <div class="mobile-header-photo-container mx-auto relative top-8">
                    <img class="mobile-header-photo rounded-full w-full h-full absolute" :src="profilePicture" alt="">
                    <div class="mobile-header-photo-circle w-full h-full absolute rounded-full"></div>
                </div>
            </div>

            <div class="pr-4 w-2/12 flex justify-end items-center">
                <div
                    class="cursor-pointer text-white inline-flex"
                    @click.prevent.stop="menuIsVisible = !menuIsVisible">
                    <icon name="menu" class="w-8 sm:w-9 align-middle"/>
                </div>
            </div>
        </div>

        <transition mode="out-in"
                    enter-active-class="transition-all duration-300"
                    leave-active-class="transition-all duration-300"
                    enter-from-class="opacity-0"
                    leave-to-class="opacity-0">
            <section v-show="menuIsVisible"
                     class="mobile-header-menu-container fixed top-0 w-full h-screen overflow-y-scroll z-40">
                <div class="mobile-header-menu flex flex-col pt-40 min-h-full">
                    <div class="text-center">
                        <h1 class="mobile-header-menu-user-name break-words mx-8 leading-6 font-bold">
                            <span class="font-normal">{{ firstName }}</span><br>{{ lastName }}
                        </h1>
                    </div>

                    <nav class="mobile-header-menu-nav flex-grow flex items-center justify-center py-8">
                        <ul>
                            <li>
                                <router-link :to="{name: 'account-profile'}" @click="menuIsVisible = false">
                                    <span class="mobile-header-menu-nav-ball"></span>
                                    <span
                                        class="mobile-header-menu-nav-text mobile-header-menu-nav-profile">Profile</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'account-password'}" @click="menuIsVisible = false">
                                    <span class="mobile-header-menu-nav-ball"></span>
                                    <span
                                        class="mobile-header-menu-nav-text mobile-header-menu-nav-password">Password</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'account-apps'}" @click="menuIsVisible = false">
                                    <span class="mobile-header-menu-nav-ball"></span>
                                    <span class="mobile-header-menu-nav-text mobile-header-menu-nav-apps">My Apps</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'account-legal'}" @click="menuIsVisible = false">
                                    <span class="mobile-header-menu-nav-ball"></span>
                                    <span class="mobile-header-menu-nav-text mobile-header-menu-nav-legal">Legal</span>
                                </router-link>
                            </li>
                            <li>
                                <a href="#" @click.prevent="logOut">
                                    <span class="mobile-header-menu-nav-ball"></span>
                                    <span
                                        class="mobile-header-menu-nav-text mobile-header-menu-nav-logout">Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <footer class="py-5 xl2:py-8 px-8 xl2:px-10 text-center">
                        <div class="mobile-header-menu-footer-text-color">
                            <img src="@/assets/img/account/dayllo-logo-footer.svg"
                                 class="w-12 xl2:w-16 mb-2 xl2:mb-3 opacity-70 mx-auto"
                                 alt="">
                            <p class="leading-tight xl2:leading-snug text-xs">Copyright © {{ year }}.</p>
                            <p class="leading-tight xl2:leading-snug text-xs">All rights reserved. | WISIT, s.r.o.</p>
                        </div>
                    </footer>
                </div>
            </section>
        </transition>
    </header>
</template>

<script>
import Icon from '@/components/front/Icon';
import AxiosUtils from '@/utils/AxiosUtils';
import {notificationMixin} from '@/utils/notification';

export default {
    name: 'MobileHeader',
    components: {Icon},
    mixins: [notificationMixin],
    props: {
        showBackButton: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            firstName: this.$store.getters['auth/firstName'],
            lastName: this.$store.getters['auth/lastName'],
            profilePicture: this.$store.getters['auth/profilePicture'],
            year: new Date().getFullYear(),
            menuIsVisible: false,
        }
    },

    mounted() {
        this.profilePictureUnwatch = this.$store.watch(
            (state, getters) => getters['auth/profilePicture'],
            newValue => this.profilePicture = newValue,
        );

        this.firstNameUnwatch = this.$store.watch(
            (state, getters) => getters['auth/firstName'],
            newValue => this.firstName = newValue,
        );

        this.lastNameUnwatch = this.$store.watch(
            (state, getters) => getters['auth/lastName'],
            newValue => this.lastName = newValue,
        );
    },

    beforeUnmount() {
        this.profilePictureUnwatch?.();
        this.firstNameUnwatch?.();
        this.lastNameUnwatch?.();
    },

    methods: {
        logOut() {
            this.$http.post(this.$urls.authLogout, {}).then(res => {
                this.clearNotifications();

                if (AxiosUtils.isSuccess(res)) {
                    window.location.href = process.env.BASE_URL;
                } else {
                    this.errorNotification();
                }
            }).catch(err => {
                this.clearNotifications();

                if ('response' in err && err.response) {
                    if (AxiosUtils.redirectIfUnauthorized(err.response)) {
                        return;
                    } else if (AxiosUtils.isForbidden(err.response)) {
                        window.location.href = process.env.BASE_URL;
                        return;
                    }
                }

                this.errorNotification();
            });
        },

        back() {
            this.menuIsVisible = false;
            this.$router.back();
        },
    },
}
</script>

<style lang="scss" scoped>
@use "../../scss/abstracts" as abs;

.mobile-header {
    background: #243454;
}

.mobile-header-photo-container {
    width: 6.75rem;
    height: 6.75rem;
}

.mobile-header-photo {
    background-color: #243454;
    padding: 0.3125rem;
}

.mobile-header-photo-circle {
    border: 0.375rem solid #2a4572;
}

.mobile-header-menu-container {
    background: #243454;
}

.mobile-header-menu-user-name {
    font-size: 1.4rem;
    color: #c0cae5;
}

.mobile-header-menu-nav li {
    margin-bottom: 0.25rem;

    a {
        display: block;
        color: white;
        padding-right: 1.5rem;
        text-align: center;

        .mobile-header-menu-nav-ball {
            display: inline-block;
            margin-right: 1rem;
            visibility: hidden;
            box-shadow: 0 0 5px #ffffff4b;
            vertical-align: middle;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: white;
        }

        .mobile-header-menu-nav-text {
            display: inline-block;
            font-size: 1.125rem;
            line-height: 1.5;
            transition: all 0.2s ease-in-out;
            vertical-align: middle;
        }

        &.router-link-active {
            .mobile-header-menu-nav-text {
                font-weight: 600;
                transform: scale(1.2);
            }

            .mobile-header-menu-nav-profile {
                color: #49cbfc;
            }

            .mobile-header-menu-nav-password {
                color: #ffb600;
            }

            .mobile-header-menu-nav-apps {
                color: #78e002;
            }

            .mobile-header-menu-nav-legal {
                color: #ff5b9d;
            }

            .mobile-header-menu-nav-logout {
                color: #ff2323;
            }
        }

        &.router-link-active .mobile-header-menu-nav-ball {
            visibility: visible;
        }
    }
}

.mobile-header-menu-footer-text-color {
    color: #c0cae596;
}
</style>
