import {mapActions, mapGetters} from "vuex";

class Modal {
    constructor(title, message, positiveText, negativeText, positiveAction, negativeAction, cancelAction) {
        this.title = title;
        this.message = message;
        this.positiveText = positiveText;
        this.negativeText = negativeText;
        this.positiveAction = positiveAction;
        this.negativeAction = negativeAction;
        this.cancelAction = cancelAction;
    }
}

const modalMixin = {
    methods: {
        ...mapActions('modal', {
            addModal: 'add',
            pollModal: 'poll',
            clearModals: 'clear',
            setIsModalActive: 'setIsActive',
        }),
        ...mapGetters('modal', {
            isModalActive: 'isActive',
        }),
    },
};

export {Modal, modalMixin};
