import Home from '@/views/account/Home';
import HomeApps from '@/views/account/HomeApps';

const routes = [
    {
        path: '/account',
        name: 'account',
        component: Home,
        meta: {
            title: 'Dayllo account',
            checkAuth: true,
        },
        redirect: {name: 'account-apps'},
        children: [
            {
                path: 'apps',
                component: HomeApps,
                children: [
                    {
                        path: '',
                        name: 'account-apps',
                        component: () => import(/* webpackChunkName: "account-apps" */ '@/views/account/Apps'),
                    },
                    {
                        path: 'account',
                        name: 'account-account',
                        component: () => import(/* webpackChunkName: "account-account" */ '@/views/account/AppAccount'),
                    },
                    {
                        path: 'ideas',
                        name: 'account-ideas',
                        component: () => import(/* webpackChunkName: "account-ideas" */ '@/views/account/AppIdeas'),
                    },
                ],
            },
            {
                path: 'profile',
                name: 'account-profile',
                component: () => import(/* webpackChunkName: "account-profile" */ '@/views/account/Profile'),
            },
            {
                path: 'password',
                name: 'account-password',
                component: () => import(/* webpackChunkName: "account-password" */ '@/views/account/Password'),
            },
            {
                path: 'legal',
                name: 'account-legal',
                component: () => import(/* webpackChunkName: "account-legal" */ '@/views/account/Legal'),
            },
        ],
    },
];

export default routes;
