<template>
    <transition
        mode="out-in"
        @afterLeave="onHidden"
        name="notification">
        <div
            :class="['notification text-white transition-all duration-500 z-999 flex items-center py-4 pr-5 sm:pr-8 pl-4 sm:pl-6', background]"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
            v-if="isActive">
            <div class="mr-3 sm:mr-5">
                <div class="relative cursor-pointer align-middle transition-all group hover:scale-125" @click="close">
                    <div class="rounded-full transition-all w-7 h-7 bg-white opacity-0 group-hover:opacity-30"></div>
                    <icon class="absolute center w-4 h-4 transition-all" name="times"/>
                </div>
            </div>
            <p class="notification-text align-middle flex-grow text-sm sm:text-base">{{ message }}</p>
            <button v-if="actionText && action"
                    @click.prevent="invokeAction"
                    class="font-bold text-base">{{ actionText }}
            </button>
        </div>
    </transition>
</template>

<script>
import Icon from '@/components/front/Icon';
import {notificationMixin, NotificationType} from '@/utils/notification';

const SHOW_DURATION = 5_000;
const SHOW_DURATION_ALT = 1_000;

export default {
    name: 'Notification',
    components: {Icon},
    mixins: [notificationMixin],
    data() {
        return {
            notification: null,
            isActive: false,
            stopHiding: false,
            timeout: null,
        };
    },

    mounted() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters['notification/head'],
            newValue => {
                if (newValue !== null && this.notification === null) {
                    this.notification = newValue;
                    this.isActive = true;
                }
            },
            {deep: true},
        );

        let existing = this.$store.getters['notification/head'];
        if (existing !== null) {
            this.notification = existing;
            this.isActive = true;
        }
    },

    beforeUnmount() {
        this.unwatch?.();
    },

    watch: {
        isActive(newValue) {
            if (this.timeout !== null) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }

            if (newValue) {
                this.timeout = setTimeout(() => {
                    // Clear timeout immediately and wait for mouseout event
                    if (this.stopHiding) {
                        clearTimeout(this.timeout);
                        this.timeout = null;
                    } else {
                        this.isActive = false;
                    }
                }, SHOW_DURATION);
            }
        },
    },

    computed: {
        message() {
            let n = this.notification;
            if (n !== null) {
                return n.message;
            } else {
                return '';
            }
        },

        actionText() {
            let n = this.notification;
            if (n !== null) {
                return n.actionText;
            } else {
                return null;
            }
        },

        action() {
            let n = this.notification;
            if (n !== null && typeof n.action === 'function') {
                return n.action;
            } else {
                return null;
            }
        },

        background() {
            let n = this.notification;
            if (n === null) {
                return NotificationType.DEFAULT;
            }

            switch (n.type) {
                case NotificationType.ERROR:
                    return 'bg-error';
                case NotificationType.SUCCESS:
                    return 'bg-success';
                default:
                    return 'bg-light';
            }
        },
    },

    methods: {
        close() {
            if (this.isActive) {
                this.isActive = false;
            }
        },

        onHidden() {
            this.notification = null;
            this.pollNotification();
        },

        onMouseEnter() {
            this.stopHiding = true;
        },

        onMouseLeave() {
            if (!this.stopHiding) {
                return;
            }

            this.stopHiding = false;

            // When a timeout is already cleared, create a new shorter one
            if (this.isActive && this.timeout === null) {
                this.timeout = setTimeout(() => this.isActive = false, SHOW_DURATION_ALT);
            }
        },

        invokeAction() {
            this.stopHiding = false;
            this.close();
            this.action?.();
        }
    },
}
</script>

<style lang="scss" scoped>
@use "sass:color";
@use "../../scss/abstracts" as abs;

.notification {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
}

.notification.bg-success:hover {
    box-shadow: 2px 2px 10px color.scale(abs.$color-success, $alpha: -25%);
}

.notification.bg-error:hover {
    box-shadow: 2px 2px 10px color.scale(abs.$color-error, $alpha: -25%);
}

.notification.bg-light:hover {
    box-shadow: 2px 2px 10px color.scale(abs.$color-light, $alpha: -25%);
}

.notification-enter-from,
.notification-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}

@media (min-width: abs.$breakpoint-lg) {
    .notification {
        bottom: 0;
        top: unset;
    }

    .notification-enter-from,
    .notification-leave-to {
        transform: translateY(100%);
    }
}
</style>
