import Home from '@/views/front/Home';
import {checkIdeaExistence, newPasswordTokenCheck} from '@/utils/guards';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'Dayllo - Great Ideas Together',
            description: 'Dayllo ideas is the best tool for managing ideas. Keep your ideas safe and nicely ' +
                'organized, involve your team and make the best results together.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, great ideas, ' +
                'great ideas together, managing ideas',
            checkAuth: true,
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/front/About'),
        meta: {
            title: 'About Us | Dayllo',
            description: 'We are at the beginning of our journey to make the world a more effective place with our ' +
                'powerful tools.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, say day to your ' +
                'hello',
            checkAuth: true,
        },
    },
    {
        path: '/get-idea',
        name: 'get-idea',
        component: () => import(/* webpackChunkName: "get-idea" */ '@/views/front/GetIdea'),
        meta: {
            title: 'Get idea | Dayllo',
            description: 'Dayllo keeps your ideas safe, fun and easy to manage.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, managing ideas, ' +
                'capture ideas, heart ideas, discuss idea',
            checkAuth: true,
        },
    },
    {
        path: '/share-idea',
        name: 'share-idea',
        component: () => import(/* webpackChunkName: "share-idea" */ '@/views/front/ShareIdea'),
        meta: {
            title: 'Share idea | Dayllo',
            description: 'Get your team involved and make ideas better together.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, share your ideas, ' +
                'loveable idea, idea grow, edit idea',
            checkAuth: true,
        },
    },
    {
        path: '/rate-idea',
        name: 'rate-idea',
        component: () => import(/* webpackChunkName: "rate-idea" */ '@/views/front/RateIdea'),
        meta: {
            title: 'Rate idea | Dayllo',
            description: 'Help your team decide which ideas are worthy of action.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, discuss ideas',
            checkAuth: true,
        },
    },
    {
        path: '/auth/login',
        name: 'auth-login',
        component: () => import(/* webpackChunkName: "auth-login" */ '@/views/front/LogIn'),
        meta: {
            title: 'Log In | Dayllo',
            description: 'Log in to your Dayllo account to see your profile.',
            keywords: 'dayllo',
            checkAuth: true,
        },
    },
    {
        path: '/auth/reset-password',
        name: 'auth-reset-password',
        component: () => import(/* webpackChunkName: "auth-reset-password" */ '@/views/front/ResetPassword'),
        meta: {
            title: 'Reset Password | Dayllo',
            disableIndex: true,
            checkAuth: true,
        },
    },
    {
        path: '/auth/new-password',
        name: 'auth-new-password',
        component: () => import(/* webpackChunkName: "auth-new-password" */ '@/views/front/NewPassword'),
        meta: {
            title: 'New Password | Dayllo',
            disableIndex: true,
        },
        beforeEnter: [newPasswordTokenCheck],
    },
    {
        path: '/auth/verify',
        name: 'auth-verify',
        component: () => import(/* webpackChunkName: "auth-verify" */ '@/views/front/Verify'),
        meta: {
            title: 'Email verification | Dayllo',
            disableIndex: true,
        },
    },
    {
        path: '/get-app',
        name: 'get-app',
        component: () => import(/* webpackChunkName: "get-app" */ '@/views/front/GetApp'),
        meta: {
            title: 'Get the App | Dayllo',
            description: 'Get your • ideas now. Start with free fuel on your mobile and tablet.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, free fuel',
            checkAuth: true,
        },
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: () => import(/* webpackChunkName: "terms-of-use" */ '@/views/front/TermsOfUse'),
        meta: {
            title: 'Terms of Use | Dayllo',
            description: 'Dayllo ideas is the best tool for managing ideas. Keep your ideas safe and nicely ' +
                'organized, involve your team and make the best results together.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, great ideas, ' +
                'great ideas together, managing ideas',
            checkAuth: true,
        },
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/front/PrivacyPolicy'),
        meta: {
            title: 'Privacy Policy | Dayllo',
            description: 'Dayllo ideas is the best tool for managing ideas. Keep your ideas safe and nicely ' +
                'organized, involve your team and make the best results together.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, get idea, share idea, rate idea, great ideas, ' +
                'great ideas together, managing ideas',
            checkAuth: true,
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '@/views/front/Faq'),
        meta: {
            title: 'FAQ | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
        },
    },
    {
        path: '/faq/about-dayllo',
        name: 'faq-about-dayllo',
        component: () => import(/* webpackChunkName: "faq-about-dayllo" */ '@/views/front/FaqAboutDayllo'),
        meta: {
            title: 'FAQ - About Dayllo ideas| Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/registration-process',
        name: 'faq-registration-process',
        component: () => import(/* webpackChunkName: "faq-registration-process" */ '@/views/front/FaqRegistrationProcess'),
        meta: {
            title: 'FAQ - Registration Process | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/creating-ideas',
        name: 'faq-creating-ideas',
        component: () => import(/* webpackChunkName: "faq-creating-ideas" */ '@/views/front/FaqCreatingIdeas'),
        meta: {
            title: 'FAQ - Creating Ideas | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/creating-groups',
        name: 'faq-creating-groups',
        component: () => import(/* webpackChunkName: "faq-creating-groups" */ '@/views/front/FaqCreatingGroups'),
        meta: {
            title: 'FAQ - Creating Groups | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/sharing-ideas',
        name: 'faq-sharing-ideas',
        component: () => import(/* webpackChunkName: "faq-sharing-ideas" */ '@/views/front/FaqSharingIdeas'),
        meta: {
            title: 'FAQ - Sharing Ideas | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/rating-ideas',
        name: 'faq-rating-ideas',
        component: () => import(/* webpackChunkName: "faq-rating-ideas" */ '@/views/front/FaqRatingIdeas'),
        meta: {
            title: 'FAQ - Rating Ideas | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/comments',
        name: 'faq-comments',
        component: () => import(/* webpackChunkName: "faq-comments" */ '@/views/front/FaqComments'),
        meta: {
            title: 'FAQ - Comments | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/searching',
        name: 'faq-searching',
        component: () => import(/* webpackChunkName: "faq-searching" */ '@/views/front/FaqSearching'),
        meta: {
            title: 'FAQ - Searching in the App | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/notifications',
        name: 'faq-notifications',
        component: () => import(/* webpackChunkName: "faq-notifications" */ '@/views/front/FaqNotifications'),
        meta: {
            title: 'FAQ - Notifications | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/my-account',
        name: 'faq-my-account',
        component: () => import(/* webpackChunkName: "faq-my-account" */ '@/views/front/FaqMyAccount'),
        meta: {
            title: 'FAQ - My Account | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/faq/subscription',
        name: 'faq-subscription',
        component: () => import(/* webpackChunkName: "faq-subscription" */ '@/views/front/FaqSubscription'),
        meta: {
            title: 'FAQ - Subscription | Dayllo',
            description: 'All info you need and more about Dayllo ideas.',
            keywords: 'dayllo, dayllo ideas, idea, ideas, •ideas, about dayllo ideas, creating ideas, managing ' +
                'ideas, sharing ideas, rating ideas',
            checkAuth: true,
            disableIndex: true,
        },
    },
    {
        path: '/ideas/:id([0-9a-f-]+)',
        name: 'idea',
        component: () => import(/* webpackChunkName: "ideas" */ '@/views/front/Idea'),
        meta: {
            title: 'Idea | Dayllo',
            disableIndex: true,
        },
        beforeEnter: [checkIdeaExistence],
    }
];

export default routes;
