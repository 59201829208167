<template>
    <mobile-header class="mobile-header" :showBackButton="showBackButton"/>
    <sidebar class="sidebar" :showBackButton="showBackButton"/>
    <main>
        <router-view #default="{Component}">
            <transition
                enter-active-class="transition-all"
                enter-from-class="opacity-0"
                mode="out-in">
                <component :is="Component" @showBackButton="showBackButton = $event"/>
            </transition>
        </router-view>
    </main>

    <notification class="account-notification"/>
    <modal/>
</template>

<script>
import Sidebar from '@/components/account/Sidebar';
import MobileHeader from '@/components/account/MobileHeader';
import Modal from '@/components/account/Modal';
import Notification from '@/components/front/Notification';
import {notificationMixin} from '@/utils/notification';

export default {
    name: 'Home',
    components: {Notification, Modal, MobileHeader, Sidebar},
    mixins: [notificationMixin],

    data() {
        return {
            isModalActive: false,
            showBackButton: false,
        };
    },

    watch: {
        isModalActive(newValue) {
            if (newValue) {
                this.hideOverflow();
            } else {
                this.showOverflow();
            }
        },
    },

    created() {
        this.storeUnwatch = this.$store.watch(
            (state, getters) => getters['modal/isActive'],
            newValue => this.isModalActive = newValue,
        );
    },

    mounted() {
        this.isModalActive = this.$store.getters['modal/isActive'];
    },

    beforeUnmount() {
        this.storeUnwatch?.();
        this.showOverflow();
    },

    methods: {
        hideOverflow() {
            document.documentElement.classList.add('overflow-hidden');
        },

        showOverflow() {
            document.documentElement.classList.remove('overflow-hidden');
        },
    },
}
</script>

<style lang="scss" scoped>
@use "../../scss/abstracts" as abs;

.sidebar {
    display: none;
}

@media (min-width: abs.$breakpoint-lg) and (min-height: 600px) {
    .sidebar {
        display: flex;
    }

    .account-notification {
        left: 16rem;
    }

    .mobile-header {
        display: none;
    }
}

@media (min-width: abs.$breakpoint-xl2) and (min-height: 600px) {
    .account-notification {
        left: 20rem;
    }
}
</style>
