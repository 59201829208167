<template>
    <main-header/>

    <main>
        <section class="bg-body">
            <div class="banner-heading flex flex-col justify-end">
                <div class="heading text-center">
                    <h1 class="px-8 text-white font-bold leading-none">Great ideas <span
                        class="font-extralight">together</span>
                    </h1>
                    <h2 class="px-8 text-white font-extralight">Best tool for managing ideas.</h2>
                </div>
            </div>

            <div>

                <div class="lg:hidden flex justify-center mt-12">
                    <div class="relative banner-arrow-left">
                        <banner-arrow-left :to="{name: 'rate-idea'}"/>
                    </div>
                    <div class="relative banner-arrow-right">
                        <banner-arrow-right :to="{name: 'get-idea'}"/>
                    </div>
                </div>

                <div class="mx-8 sm:mx-14 md:mx-24 lg:mx-8 xl:mx-32">
                    <div class="flex flex-row justify-between items-center relative z-10 w-full">

                        <div class="hidden lg:block w-2/12 xl:w-3/12 text-center">
                            <banner-arrow-left :to="{name: 'rate-idea'}" class="inline-block"/>
                        </div>

                        <transition appear
                                    appear-active-class="transition-all duration-1000"
                                    appear-from-class="opacity-0">
                            <div
                                class="banner-image-container w-full lg:w-8/12 xl:w-6/12 flex items-end justify-center">
                                <div class="w-full flex justify-center max-h-full">
                                    <img src="@/assets/img/pages/home/blue.svg" alt=""
                                         class="cosmonaut-blue cosmonaut hidden sm:block self-start">
                                    <img src="@/assets/img/pages/home/mobile-home.svg" alt=""
                                         class="mobile">
                                    <img src="@/assets/img/pages/home/pink.svg" alt=""
                                         class="cosmonaut-pink cosmonaut hidden sm:block self-start">
                                </div>
                            </div>
                        </transition>

                        <div class="hidden lg:block w-2/12 xl:w-3/12 text-center">
                            <banner-arrow-right :to="{name: 'get-idea'}" class="inline-block"/>
                        </div>

                    </div>
                </div>

                <planet class="relative translate-y-px"/>
            </div>
        </section>

        <section class="text-center py-8 relative sm:-top-24 sm:h-68 md:h-44 xl:-top-48 xl:h-32 xl2:h-40">
            <div>
                <h1 class="title-ideas text-primary font-bold px-4 leading-8 sm:leading-normal">
                    Get your • ideas now
                </h1>
                <h2 class="title-fuel text-light text-xl px-4">Start with free fuel:</h2>

                <div class="w-full text-center">
                    <a href="https://play.google.com/store/apps/details?id=com.dayllo.android.ideas"
                       class="inline-block pb-4 pl-4 pr-4">
                        <img src="@/assets/img/prefooter/google-play-badge.svg" alt="Google play"
                             class="w-60 md:w-auto md:h-16 hover:scale-110 transition-all">
                    </a>
                </div>
            </div>
        </section>
    </main>

    <footer-info class="mt-8 xl2:mt-40"/>
    <notification/>
</template>

<script>
import FooterInfo from '@/components/front/FooterInfo';
import MainHeader from '@/components/front/MainHeader';
import Planet from '@/components/front/Planet';
import BannerArrowLeft from '@/components/front/BannerArrowLeft';
import BannerArrowRight from '@/components/front/BannerArrowRight';
import Notification from '@/components/front/Notification';

export default {
    name: 'Home',
    components: {Notification, BannerArrowRight, BannerArrowLeft, FooterInfo, MainHeader, Planet},
}
</script>

<style lang="scss" scoped>
@use '../../scss/abstracts' as abs;

.heading {
    h1 {
        font-size: 2.25rem;
        letter-spacing: -0.05rem;
        margin-bottom: 1rem;

        span {
            letter-spacing: -0.15rem;
        }
    }

    h2 {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}

.banner-heading {
    height: 40vh;
}

.banner-arrow-left {
    right: 5%;
}

.banner-arrow-right {
    left: 5%;
}

.mobile {
    width: 80%;
    max-width: 50vh;
    transform: translate(6%, 15%);
}

.cosmonaut {
    width: 20%;
    min-height: 5rem;
    max-width: 15vh;
}

.cosmonaut-blue {
    transform: translateY(80%);
    animation: anim-move-up-down-blue 8s ease-in-out infinite;
    transition: all 1s ease-in-out;

    @keyframes anim-move-up-down-blue {
        0% {
            transform: translateY(80%);
        }
        50% {
            transform: translateY(50%) rotate(-10deg);
        }
        100% {
            transform: translateY(80%);
        }
    }
}

.cosmonaut-pink {
    transform: translateY(50%);
    animation: anim-move-up-down-pink 8s ease-in-out infinite;
    transition: all 1s ease-in-out;

    @keyframes anim-move-up-down-pink {
        0% {
            transform: translateY(50%);
        }
        50% {
            transform: translateY(80%) rotate(10deg);
        }
        100% {
            transform: translateY(50%);
        }
    }
}

.banner-image-container {
    height: 34vh;
    max-width: abs.$breakpoint-lg;
}

@media (min-width: abs.$breakpoint-sm) {
    .banner-image-container {
        height: 40vh;
    }

    .mobile {
        max-width: 60%;
    }
}

@media (min-width: abs.$breakpoint-xs) {
    .heading h1 {
        font-size: 3rem;
    }
}

@media (min-width: abs.$breakpoint-sm) and (max-height: 600px) {
    .banner-image-container {
        height: 55vh;
    }
}

@media (min-width: abs.$breakpoint-md) {
    .mobile {
        transform: translate(6%, 15%);
    }

    .heading {
        h1 {
            font-size: 8vh;
            margin-bottom: 3vh;
        }

        h2 {
            font-size: 1.25rem;
            margin-bottom: 4vh;
        }
    }
}

@media (min-width: abs.$breakpoint-lg) {
    .banner-image-container {
        height: 50vh;
    }
}

@media (min-width: abs.$breakpoint-xl) {
    .heading {
        h1 {
            font-size: 4rem;
            margin-bottom: 1.2rem;

            span {
                letter-spacing: -0.3rem;
            }
        }
    }
}

@media (min-width: abs.$breakpoint-xl2) {
    .heading {
        h1 {
            font-size: 6rem;
            margin-bottom: 2vh;
        }

        h2 {
            font-size: 1.6rem;
            margin-bottom: 6vh;
        }
    }
}

@media (max-height: 600px) {
    .heading h2 {
        font-size: 1rem;
    }

    .banner-heading {
        height: unset;
        padding-top: 8rem;
    }
}

// Pre footer
.title-ideas {
    font-size: 1.6rem;
    padding-bottom: 0.4rem;
}

.title-fuel {
    padding-bottom: 2rem;
}

@media (min-width: abs.$breakpoint-sm) {
    .title-ideas {
        font-size: 2rem;
    }
}

@media (min-width: abs.$breakpoint-md) {
    .title-ideas {
        font-size: 2.2rem;
    }
}

@media (min-width: abs.$breakpoint-lg) {
    .title-ideas {
        font-size: 2.5rem;
    }
}

@media (min-width: abs.$breakpoint-xl) {
    .title-ideas {
        font-size: 2.8rem;
    }

    .title-fuel {
        padding-bottom: 3.5rem;
    }
}

@media (min-width: abs.$breakpoint-xl2) {
    .title-ideas {
        font-size: 4rem;
        padding-bottom: 0;
    }

    .title-fuel {
        font-size: 1.4rem;
        padding-bottom: 4rem;
    }
}
</style>
