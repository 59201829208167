class AxiosUtils {

    static isSuccess(response) {
        if ('status' in response) {
            const status = parseInt(response.status);
            if (!isNaN(status) && status >= 200 && status < 300) {
                return true;
            }
        }

        return false;
    }

    static isCreated(response) {
        return this.isStatus(response, 201);
    }

    static isBadRequest(response) {
        return this.isStatus(response, 400);
    }

    static isUnauthorized(response) {
        return this.isStatus(response, 401);
    }

    static isForbidden(response) {
        return this.isStatus(response, 403);
    }

    static isNotFound(response) {
        return this.isStatus(response, 404);
    }

    static isConflict(response) {
        return this.isStatus(response, 409);
    }

    static isTooManyRequests(response) {
        return this.isStatus(response, 429);
    }

    static redirectIfUnauthorized(response) {
        if (this.isUnauthorized(response)) {
            window.location.href = process.env.BASE_URL + 'auth/login';
            return true;
        } else {
            return false;
        }
    }

    static isStatus(response, status) {
        if ('status' in response) {
            return response.status === status;
        }

        return false;
    }

    static getAllFieldErrors(response, field) {
        if ('data' in response && response.data && 'errors' in response.data) {
            if (field in response.data.errors) {
                return response.data.errors[field];
            }
        }

        return null;
    }

    static getFieldError(response, field) {
        const fieldErrors = this.getAllFieldErrors(response, field);

        if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
            const fieldError = fieldErrors[0];
            if (fieldError !== undefined) {
                return fieldError;
            }
        }

        return null;
    }
}

export default AxiosUtils;
