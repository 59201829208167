<template>
    <span ref="parent" class="icon"></span>
</template>

<script>
import iconsMixin from '@/utils/icons';

export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: false,
        },
    },

    mixins: [iconsMixin],

    watch: {
        icon(value) {
            this.setIcon(this.getIcon(value));
        }
    },

    mounted() {
        this.setIcon(this.getIcon(this.name));
    },

    methods: {
        setIcon(icon) {
            if (icon !== null) {
                this.$refs.parent.innerHTML = icon;
            } else {
                this.$refs.parent.innerHTML = '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.icon {
    fill: currentColor;
    overflow: visible;
    color: inherit;
    font: inherit;
    text-transform: none;
    display: inline-block;
    line-height: 0;

    ::v-deep([fill*="#000"]) {
        fill: currentColor;
    }

    ::v-deep([stroke*="#000"]) {
        stroke: currentColor;
    }

    ::v-deep(svg) {
        width: 100%;
        height: auto;
    }
}
</style>
