const accountBaseURL = process.env.VUE_APP_DAYLLO_ACCOUNT_BASE_URL;
const ideasBaseURL = process.env.VUE_APP_DAYLLO_IDEAS_BASE_URL;

const urls = {
    accountBillingGoogle: `${accountBaseURL}account/billing/google`,
    accountClose: `${accountBaseURL}account/close`,
    accountProfile: `${accountBaseURL}account/profile`,
    accountProfileEmail: `${accountBaseURL}account/profile-email`,
    accountProfilePassword: `${accountBaseURL}account/profile-password`,
    accountProfilePicture: `${accountBaseURL}account/profile-pictures/{id}`,
    accountProfilePictures: `${accountBaseURL}account/profile-pictures`,
    accountRequestEmailVerification: `${accountBaseURL}account/request-email-verification`,
    accountSubscriptions: `${accountBaseURL}account/subscriptions`,
    authLogin: `${accountBaseURL}auth/login`,
    authLoginGoogle: `${accountBaseURL}auth/login?t=google`,
    authLogout: `${accountBaseURL}auth/logout`,
    authNewPassword: `${accountBaseURL}auth/new-password`,
    authResetPassword: `${accountBaseURL}auth/reset-password`,
    authVerify: `${accountBaseURL}auth/verify`,
    bootstrap: `${accountBaseURL}bootstrap`,
    idea: `${ideasBaseURL}ideas/{id}`
}

export default (function () {
    let obj = Object.create({});

    for (let routeKey in urls) {
        const route = urls[routeKey];
        const matches = Array.from(route.matchAll(/{(\w+?)}/g));

        if (matches.length === 0) {
            Object.defineProperty(obj, routeKey, {value: route});
        } else {
            Object.defineProperty(obj, routeKey, {
                value: function (params) {
                    if (typeof params !== 'object') {
                        throw Error('Invalid route parameter value');
                    }

                    let replaced = route;
                    for (let param in params) {
                        let replaceValue = params[param];
                        if (replaceValue === undefined || replaceValue === null) {
                            throw new Error('Invalid route parameter value');
                        }

                        for (let match of matches) {
                            if (match[1] === param) {
                                replaced = replaced.replace(match[0], params[param]);
                            }
                        }
                    }

                    return replaced;
                }
            });
        }
    }

    return obj;
})();
