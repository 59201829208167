<template>
    <transition
        mode="out-in"
        @afterLeave="onHidden"
        enter-from-class="opacity-0 translate-y-full"
        leave-to-class="opacity-0 translate-y-full">
        <div class="modal fixed flex top-0 right-0 bottom-0 left-0 z-999 py-10 px-4 overflow-y-auto"
             @click.prevent="onCancelClick"
             v-if="isActive">
            <div class="modal-content max-w-full bg-white m-auto px-8 sm:px-16 py-6 sm:py-12 rounded-xl4" @click.stop>
                <h1 v-html="modal.title" class="text-xl3 leading-tight text-primary font-bold mt-2"></h1>
                <p v-html="modal.message" class="mt-7 leading-tight text-light"></p>
                <div class="flex flex-wrap justify-end mt-12">
                    <button
                        class="modal-btn-negative transition-all text-white text-base leading-tight py-3 px-8 rounded-xl2 ml-4 mt-4"
                        @click="onNegativeClick">{{ modal.negativeText }}
                    </button>
                    <button
                        class="modal-btn-positive transition-all text-white text-base leading-tight py-3 px-8 rounded-xl2 ml-4 mt-4"
                        @click="onPositiveClick">{{ modal.positiveText }}
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {modalMixin} from '@/utils/modal';

export default {
    name: 'Modal',
    mixins: [modalMixin],
    data() {
        return {
            modal: null,
            isActive: false,
        };
    },

    watch: {
        isActive(newValue) {
            this.setIsModalActive(newValue);
        },
    },

    mounted() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters['modal/head'],
            newValue => {
                if (newValue !== null && this.modal === null) {
                    this.modal = newValue;
                    this.isActive = true;
                }
            },
            {deep: true},
        );
    },

    beforeUnmount() {
        this.unwatch?.();
    },

    methods: {
        onHidden() {
            this.modal = null;
            this.pollModal();
        },

        onPositiveClick() {
            this.modal?.positiveAction?.();
            this.isActive = false;
        },

        onNegativeClick() {
            this.modal?.negativeAction?.();
            this.isActive = false;
        },

        onCancelClick() {
            this.modal?.cancelAction?.();
            this.isActive = false;
        },
    },
}
</script>

<style lang="scss" scoped>
@use "sass:color";
@use "../../scss/abstracts" as abs;

.modal {
    background: #000000B2;
}

.modal-content {
    width: 46rem;
}

.modal-btn-negative {
    background: #b8c4df;

    &:hover {
        background: #91a4d1;
    }
}

.modal-btn-positive {
    background: #FF0953;

    &:hover {
        background: #D80A2D;
    }
}
</style>
