import {createStore} from 'vuex';
import auth from '@/store/auth';
import notification from '@/store/notification';
import modal from '@/store/modal';

const store = createStore({
    modules: {
        auth: auth,
        notification: notification,
        modal: modal,
    },

    state: {
        bootstrapped: false,
    },
});

export default store;
