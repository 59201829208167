import {createRouter, createWebHistory} from 'vue-router';
import frontRoutes from './front';
import accountRoutes from './account';
import errorRoutes from './error';
import {bootstrap, checkAuthentication, updateDocumentMeta} from '@/utils/guards';

const routes = [
    ...frontRoutes,
    ...accountRoutes,
    ...errorRoutes,
];

// noinspection JSCheckFunctionSignatures
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    }
});

router.beforeEach(bootstrap);
router.beforeEach(checkAuthentication);
router.afterEach(updateDocumentMeta);

export default router;
