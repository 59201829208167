<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="1440.76" height="287.16"
             viewBox="0 0 1440.76 287.16" class="planet w-full h-auto">
            <path d="M1440.76,287.16H0C187.71,109.18,441.29,0,720.38,0S1253.05,109.17,1440.76,287.16Z"
                  fill="#fff"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Planet',
}
</script>

<style lang="scss" scoped>
.planet {
    filter: drop-shadow(0px 0px 30px #ffffff99);
}
</style>
