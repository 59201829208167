import {createApp} from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import urls from '@/utils/urls';
import axiosInstance from '@/utils/api';
import isMobile from '@/utils/mobile-detect';

const app = createApp(App)
    .use(store)
    .use(router);

app.config.globalProperties.$http = axiosInstance;
app.config.globalProperties.$urls = urls;
app.config.globalProperties.$isMobile = isMobile;

app.mount('#app');
