import {mapActions} from 'vuex';

class Notification {
    constructor(message, type, actionText = null, action = null) {
        this.message = message;
        this.type = type;
        this.actionText = actionText;
        this.action = action;
    }
}

const NotificationType = Object.freeze({
    DEFAULT: 'default',
    SUCCESS: 'success',
    ERROR: 'error',
});

const notificationMixin = {
    methods: {
        ...mapActions('notification', {
            addNotification: 'add',
            defaultNotification: 'default',
            successNotification: 'success',
            errorNotification: 'error',
            pollNotification: 'poll',
            clearNotifications: 'clear',
        }),
    },
};

export {Notification, NotificationType, notificationMixin};
