<template>
    <div>
        <transition
            mode="out-in"
            enter-active-class="transition-all duration-200"
            leave-active-class="transition-all duration-200 delay-100"
            enter-from-class="opacity-0"
            leave-to-class="opacity-0">
            <div v-show="menuIsVisible"
                 class="header-nav text-primary z-10 bg-white rounded-xl4 py-5 px-4 relative z-10 mb-3">
                <ul>
                    <li class="rounded-xl2 transition-all duration-100 group">
                        <router-link :to="{name: 'get-app'}" class="flex items-center py-3 px-4 text-primary">
                            <img class="mr-4 w-8 transition-all xl:group-hover:scale-110"
                                 src="@/assets/img/menu/nav-get-the-app.svg"
                                 alt="">
                            <p class="w-32 flex-grow">Get the App</p>
                            <icon name="caret-right"
                                  class="header-nav-caret ml-4 w-4 transition-all xl:group-hover:translate-x-1"/>
                        </router-link>
                    </li>
                    <li class="rounded-xl2 transition-all duration-100 group">
                        <router-link :to="{name: 'terms-of-use'}" class="flex items-center py-3 px-4 text-primary">
                            <img class="mr-4 w-8 transition-all xl:group-hover:scale-110"
                                 src="@/assets/img/menu/nav-terms.svg"
                                 alt="">
                            <p class="w-32 flex-grow">Terms of Use</p>
                            <icon name="caret-right"
                                  class="header-nav-caret ml-4 w-4 transition-all xl:group-hover:translate-x-1"/>
                        </router-link>
                    </li>
                    <li class="rounded-xl2 transition-all duration-100 group">
                        <router-link :to="{name: 'privacy-policy'}"
                                     class="flex items-center py-3 px-4 text-primary">
                            <img class="mr-4 w-8 transition-all xl:group-hover:scale-110"
                                 src="@/assets/img/menu/nav-privacy.svg"
                                 alt="">
                            <p class="w-32 flex-grow">Privacy Policy</p>
                            <icon name="caret-right"
                                  class="header-nav-caret ml-4 w-4 transition-all xl:group-hover:translate-x-1"/>
                        </router-link>
                    </li>
                    <li class="rounded-xl2 transition-all duration-100 group">
                        <router-link :to="{name: 'faq'}" class="flex items-center py-3 px-4 text-primary">
                            <img class="mr-4 w-8 transition-all xl:group-hover:scale-110"
                                 src="@/assets/img/menu/nav-faq.svg"
                                 alt="">
                            <p class="w-32 flex-grow">FAQ</p>
                            <icon name="caret-right"
                                  class="header-nav-caret ml-4 w-4 transition-all xl:group-hover:translate-x-1"/>
                        </router-link>
                    </li>
                    <li class="rounded-xl2 transition-all duration-100 group">
                        <router-link :to="{name: 'about'}" class="flex items-center py-3 px-4 text-primary">
                            <img class="mr-4 w-8 transition-all xl:group-hover:scale-110"
                                 src="@/assets/img/menu/nav-about.svg"
                                 alt="">
                            <p class="w-32 flex-grow">About Us</p>
                            <icon name="caret-right"
                                  class="header-nav-caret ml-4 w-4 transition-all xl:group-hover:translate-x-1"/>
                        </router-link>
                    </li>
                </ul>
            </div>
        </transition>

        <transition
            name="fade-social"
            mode="out-in"
            enter-active-class="transition-all duration-200 delay-100"
            leave-active-class="transition-all duration-200">
            <div v-show="menuIsVisible" class="header-nav-social text-center pt-5 pb-3 bg-white rounded-xl4 w-68">
                <p class="text-primary font-semibold pb-3">Stay in touch:</p>
                <div class="header-nav-social-buttons flex justify-center">
                    <a href="https://www.facebook.com/Dayllo" target="_blank"
                       class="mx-0.5 xl:hover:scale-125 transition-all duration-200">
                        <icon name="facebook-circle" class="w-11 facebook transition-all duration-200"/>
                    </a>

                    <a href="https://www.instagram.com/dayllo_official" target="_blank"
                       class="mx-0.5 xl:hover:scale-125 transition-all duration-200">
                        <icon name="instagram-circle" class="w-11 insta transition-all duration-200"/>
                    </a>

                    <a href="https://twitter.com/dayllo_official" target="_blank"
                       class="mx-0.5 xl:hover:scale-125 transition-all duration-200">
                        <icon name="twitter-circle" class="w-11 twitter transition-all duration-200"/>
                    </a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Icon from '@/components/front/Icon';

export default {
    name: 'HeaderNav',
    components: {Icon},
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },

    emits: {
        visible: value => typeof value === 'boolean',
    },

    data() {
        return {
            menuIsVisible: this.show,
            clickListenerFunc: this.windowClickListener.bind(this),
        };
    },

    watch: {
        show(value) {
            this.menuIsVisible = value;
        }
    },

    mounted() {
        window.addEventListener('click', this.clickListenerFunc);
    },

    unmounted() {
        window.removeEventListener('click', this.clickListenerFunc);
    },

    methods: {
        windowClickListener(e) {
            let target = e.target;
            let nav = target.closest('.header-nav');
            let social = target.closest('.header-nav-social');

            if (nav === null && social === null) {
                if (this.menuIsVisible !== false) {
                    this.menuIsVisible = false;
                    this.$emit('visible', false);
                }
            } else {
                e.stopPropagation();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@use "../../scss/abstracts" as abs;

.header-nav {
    box-shadow: 2px 2px 20px #2a3a4948;
    -ms-overflow-style: none; /* IE and Edge */
    //noinspection CssUnknownProperty
    scrollbar-width: none; /* Firefox */
}

.header-nav-caret {
    color: #6980a6;
}

.header-nav-social {
    box-shadow: 2px 2px 20px #2a3a4948;
}

.header-nav-social-buttons a {
    .facebook,
    .insta,
    .twitter {
        color: #bac6df;
    }
}

@media (max-height: 550px) {
    .header-nav {
        max-height: 12.5rem;
        overflow: scroll;
    }
}

@media (min-width: abs.$breakpoint-xl) {
    .header-nav li:hover {
        background: #e3eff7;
    }

    .header-nav-social-buttons a:hover {
        .facebook {
            color: #1a72eb;
        }

        .insta {
            color: #eb1d7d;
        }

        .twitter {
            color: #209dea;
        }
    }
}

@media (min-width: abs.$breakpoint-sm) and (max-height: 550px) {
    .header-nav-social {
        position: absolute;
        top: 0;
        left: 0;
        margin-left: -1rem;
        transform: translateX(-100%);
        width: auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.fade-social-enter-from,
.fade-social-leave-to {
    opacity: 0;
    transform: translate(0, -2rem);

    @media (min-width: abs.$breakpoint-sm) and (max-height: 550px) {
        transform: translateX(10%);
    }
}

.fade-social-enter-to,
.fade-social-leave-from {
    opacity: 1;
    transform: translate(0, 0);

    @media (min-width: abs.$breakpoint-sm) and (max-height: 550px) {
        transform: translateX(-100%);
    }
}
</style>
