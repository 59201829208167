class StringUtil {
    /**
     * @param {String} value
     */
    static isBlank(value) {
        if (value === null) return true
        return value.trim().length === 0;
    }

    /**
     * @param {String} value
     */
    static sentenceCase(value) {
        if (value === null) return null;
        if (this.isBlank(value)) return value;

        const firstLetter = value[0].toUpperCase();
        if (value.length === 1) return firstLetter;

        return firstLetter + value.slice(1);
    }

    /**
     * @param {String} value
     * @returns {string|null}
     */
    static decodeBase64URL(value) {
        let replaced = value
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        replaced = replaced.padEnd(replaced.length + (4 - replaced.length % 4) % 4, '=');

        try {
            return atob(replaced);
        } catch (err) {
            return null;
        }
    }
}

export default StringUtil;
