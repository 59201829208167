<template>
    <footer class="footer-color bg-white px-8 text-xs2 pb-8">
        <p class="text-center">
            Contact us: <a href="mailto:info@dayllo.com" class="transition-all underline hover:text-light">info@dayllo.com</a>
        </p>
        <p class="text-center mt-1">Copyright © {{ year }}. All rights reserved. | WISIT, s.r.o.</p>
    </footer>
</template>

<script>
export default {
    name: 'FooterInfo',
    data() {
        return {
            year: new Date().getFullYear(),
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-color {
    color: #0f2b46;
}
</style>
