import avatars from "@/utils/AvatarEnum";
import AvatarEnum from "@/utils/AvatarEnum";

const PROFILE_PICTURE_LOCATION = process.env.VUE_APP_DAYLLO_ACCOUNT_BASE_URL + 'files/profile-pictures/';

export default {
    namespaced: true,

    state: {
        user: null,
    },

    getters: {
        user(state) {
            return state.user;
        },

        firstName(state) {
            if (state.user !== null && 'firstName' in state.user) {
                return state.user.firstName;
            }

            return null;
        },

        lastName(state) {
            if (state.user !== null && 'lastName' in state.user) {
                return state.user.lastName;
            }

            return null;
        },

        email(state) {
            if (state.user !== null && 'email' in state.user) {
                return state.user.email;
            }

            return null;
        },

        avatar(state) {
            let av = null;

            if (state.user !== null && 'avatar' in state.user) {
                av = AvatarEnum.from(state.user.avatar);
            }

            return av ?? AvatarEnum.BLUE;
        },

        /**
         * @param state
         * @returns {boolean}
         */
        isVerified(state) {
            return state.user !== null && 'verifiedAt' in state.user && state.user.verifiedAt !== null;
        },

        /**
         * @returns {boolean}
         */
        isLoggedIn(state) {
            return state.user !== null;
        },

        /**
         * @returns {boolean}
         */
        isPasswordEnabled(state) {
            return state.user !== null && 'isPasswordEnabled' in state.user && state.user.isPasswordEnabled === true;
        },

        /**
         * @returns {boolean}
         */
        hasGoogleId(state) {
            return state.user !== null && 'hasGoogleId' in state.user && state.user.hasGoogleId === true;
        },

        /**
         * @returns {(String|null)}
         */
        profilePicture(state) {
            if (state.user === null) {
                return avatars.BLUE.url;
            }

            if ('profilePicture' in state.user && state.user.profilePicture !== null) {
                return PROFILE_PICTURE_LOCATION + state.user.profilePicture.filename;
            }

            let avatar;
            if (!('avatar' in state.user)) {
                avatar = avatars.BLUE.url;
            } else {
                avatar = avatars.from(state.user.avatar)?.url ?? avatars.BLUE.url;
            }

            return avatar;
        },

        /**
         * @returns {Boolean}
         */
        hasCustomProfilePicture(state) {
            return state.user !== null && 'profilePicture' in state.user && state.user.profilePicture !== null;
        },
    },

    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },

        clearData(state) {
            state.user = null;
        },

        patch(state, payload) {
            state.user = Object.assign({}, state.user, payload);
        },
    },

    actions: {
        login({commit}, payload) {
            commit('clearData');
            commit('setUser', payload);
        },

        logout({commit}) {
            commit('clearData');
        },

        patch({commit}, payload) {
            commit('patch', payload);
        },
    },
};
