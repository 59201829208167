const routes = [
    {
        path: '/server-error',
        name: 'server-error',
        component: () => import(/* webpackChunkName: "server-error" */ '@/views/front/ServerError'),
        meta: {
            title: '500 | Dayllo',
            disableIndex: true,
        }
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: () => import(/* webpackChunkName: "unauthorized" */ '@/views/front/Unauthorized'),
        meta: {
            title: '401 | Dayllo',
            disableIndex: true,
        }
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import(/* webpackChunkName: "forbidden" */ '@/views/front/Forbidden'),
        meta: {
            title: '403 | Dayllo',
            disableIndex: true,
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '@/views/front/NotFound'),
        meta: {
            title: '404 | Dayllo',
            disableIndex: true,
        }
    },
];

export default routes;
